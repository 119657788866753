<template>
  <div class="basic">
    <v-snackbar v-model="snackbar" :timeout="6000" color="error">
      {{ errmsg }}
    </v-snackbar>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-container fluid>
        <v-row dense>
          <v-col cols="12">
            <v-row class="lighten-5 align-center justify-center">
              <v-card
                :key="1"
                class="card1 ma-2 pa-3"
                elevation=0
                flat
                width=325
                color="transparent">
                <v-row dense>
                  <v-col cols="12" align="center">
                    <v-img :width="80" src="https://s3.ap-northeast-2.amazonaws.com/images.wintersleeping/main_pic.png" class="ma-0 pa-0" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="이메일"
                      prepend-icon="email"
                      required
                      class="ma-0 pa-0"
                      background-color="transparent" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" >
                    <v-text-field
                      v-model="password"
                      :rules="passRules"
                      :type="'password'"
                      label="패스워드"
                      prepend-icon="lock"
                      required
                      class="ma-0 pa-0"
                      background-color="transparent"
                      @keyup.enter="$event.target.blur()"
                      @blur="login" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" align="center">
                    <v-btn
                      color="#FBDE44FF"
                      large
                      class="mr-4"
                      @click="login"
                      block
                    >
                    로그인
                    </v-btn>
                    <v-btn
                      text
                      to="/user/join">
                      회원가입
                    </v-btn>

                    <v-dialog v-model="pwreset_dialog" persistent max-width="300px">
                      <template v-slot:activator="{ on }">
                        <v-btn text v-on="on">비밀번호 찾기</v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">비밀번호 재설정</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field label="Email*" v-model="pwreset_email" :rules="emailRules" required></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="pwreset_dialog = false">취소</v-btn>
                          <v-btn color="blue darken-1" text @click="pwreset">전송</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <SNS />
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
/* eslint-disable no-console, no-unused-var, no-undef */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

// import KakaoLogin from 'vue-kakao-login'

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

import SNS from './SNS';

export default {
  components: {
    // KakaoLogin,
    SNS
  },
  data: () => {
    return {
      pwreset_dialog: false,
      pwreset_email:"",
      snackbar: false,
      errmsg: "",
      valid: false,
      email: "",
      emailRules: [
        v => !!v || '이메일을 입력하세요.',
        v => /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v) || '이메일이 유효하지 않습니다.',
      ],
      password: "",
      passRules: [
        v => v.length >= 8 || '최소 8글자를 입력하세요.',
      ],
    }
  },
  created: function() {
    //console.log(this.user);
  },
  computed: {
    ...mapGetters({
      user: "user",
      kakao: "kakao",
    })
  },
  watch: {
    email: async function(v) {
      if ( /.+@.+\..+/.test(v)) {

        const is_exist = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/is', {
          params: { email: this.email }
        });

        if (is_exist.data > 0) {
          this.loginbtn = true;
          this.joinbtn = false;
        } else {
          this.joinbtn = true;
          this.loginbtn = false;
        }

      }
    }
  },
  mounted: async function() {

    var naverLogin = new naver.LoginWithNaverId(
      {
        clientId: api_conf.naver.client_id,
        callbackUrl: api_conf.naver.redirect_url,
        isPopup: false, /* 팝업을 통한 연동처리 여부 */
        loginButton: {color: "green", type: 1, height: 40},
        // userLoginButtons: "https://s3.ap-northeast-2.amazonaws.com/images.wintersleeping/navericon.png",
      }
    );

    /* 설정정보를 초기화하고 연동을 준비 */
    naverLogin.init();

    // 네이버 커스텀 로그인 버튼

    let n_btn = document.getElementById('naverIdLogin_loginButton').getElementsByTagName('img')[0];
    n_btn.src = "https://s3.ap-northeast-2.amazonaws.com/images.wintersleeping/navericon.png";
    n_btn.width = "56";
    n_btn.height = "56";

  },
  methods: {
    login: async function(ev) {
      if (this.$refs.form.validate()) {

        try {

          const res = await firebase.auth().signInWithEmailAndPassword(
            this.email,
            this.password);

          await this.$store.dispatch("fetchUser", res.user);

          this.$router.push({ path:"/main" });

        } catch (e) {
          console.log(e);
          if ( e.code == "auth/wrong-password" ) {
            this.snackbar = false;
            this.snackbar = true;
            this.errmsg = "암호가 틀렸습니다";
          } else if ( e.code == "auth/too-many-requests" ) {
            this.snackbar = false;
            this.snackbar = true;
            this.errmsg = "너무 많은 로그인 시도가 실패했습니다. 나중에 다시 시도하세요.";
          } else if ( e.code == "auth/user-not-found") {
            this.snackbar = false;
            this.snackbar = true;
            this.errmsg = "가입되지 않은 계정입니다. 회원가입이 필요합니다.";
          }
        }
      }
    },
    pwreset_cancel: function() {
      this.pwreset_email = "";
      this.pwreset_dialog = false;
    },
    pwreset: async function(e){
      const auth = firebase.auth()
      if ( this.pwreset_email ) {
        auth.sendPasswordResetEmail(this.pwreset_email).then(function() {
          console.log('ok');
        }).catch(function(error) {
          console.log(error);
        });
      }
      this.pwreset_dialog = false
    },
    googlelogin: async function(ev) {
      const provider = new firebase.auth.GoogleAuthProvider();

      const res = await firebase.auth().signInWithPopup(provider);

      await this.$store.dispatch("fetchUser", res.user);

      const id_token = await firebase.auth().currentUser.getIdToken(true);

      const is_exist = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/is', {
        params: { email: res.user.email },
        headers: {id_token: id_token},
      });

      if(is_exist.data==0) {
        this.$router.push({ path: "/agreement" });
      } else {
        this.$router.push({ path:"/main" });
      }
    },
    async facebooklogin(ev) {
      const provider = new firebase.auth.FacebookAuthProvider();

      const res = await firebase.auth().signInWithPopup(provider);

      const id_token = await firebase.auth().currentUser.getIdToken(true);

      const is_exist = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/is', {
        params: { email: res.user.email },
        headers: {id_token: id_token},
      });

      if(is_exist.data==0) {
        await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user', {
          name: res.user.displayName,
          uid: res.user.uid,
          email: res.user.email,
        }, {
          headers: {'id_token': id_token},
        });
      }

      this.$router.push({ path:"/main" });
    },

    //////////////////////////////////////////////////////////////////////////////////
    kakaologin: async function() {
      Kakao.init(api_conf.kakao.id);
      Kakao.Auth.login({
        success: (authObj) => this.kakao_success(authObj),
        fail: (err) => this.kakao_failure(err)
      });
    },
    kakao_success: async function(data) {
      let customtoken = await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/kakao/auth', {
        token: data.access_token,
      });

      const res = await firebase.auth().signInWithCustomToken(customtoken.data.token);

      await this.$store.dispatch("fetchUser", res.user);

      const id_token = await firebase.auth().currentUser.getIdToken(true);

      const is_exist = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/is', {
        params: { email: customtoken.data.email },
        headers: {id_token: id_token},
      });

      if(is_exist.data==0) {
        this.$router.push({ path: "/agreement" });
      } else {
        this.$router.push({ path:"/main" });
      }
    },
    kakao_failure: async function(data) {
      this.snackbar = false;
      this.snackbar = true;
      this.errmsg = "카카오톡 로그인에 문제가 있습니다.";
    },
    reset() {
      this.email = "";
      this.password = "";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

ul li {
  margin: 10px;
  list-style:none;
}

</style>
